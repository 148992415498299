// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// Though this file does provide us with good-enough data model definitions through Typescript, the
// users of these data models are completely unprotected since they are mostly written in
// Javascript, and therefore are not checked for type safety.
//
// TODO: Figure out why we need to make arbitrary changes to API field names; the closer we can make
// these definitions match our APIs, the better we can leverage our investments into OpenAPI to
// auto-generate code instead of needing to maintain these typing files by hand.
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

export class PortfolioLocation {
  constructor(apiPayload: { [key: string]: any }) {
    this.id = apiPayload.id;
    this.street_address = apiPayload.address;
    this.name = apiPayload.name;
    this.grade_created = apiPayload.grade_created;
    this.grade_updated = apiPayload.grade_updated;
    this.portfolio_id = apiPayload.portfolio_id;
    this.is_starred = apiPayload.is_starred;
    this.is_shown = apiPayload.is_shown;
  }
  id: string;
  street_address: string;
  name: string;
  grade_created: string;
  grade_updated: string;
  portfolio_id: string;
  is_starred: boolean;
  is_shown: boolean;
}

export class PortfolioLocationOffers {
  constructor(apiPayload: { [key: string]: any }) {
    this.id = apiPayload.location_id;
    this.status = apiPayload.autoport_job_status;
    this.grade = apiPayload.grade;
    this.grade_created = apiPayload.grade_created;
    this.grade_updated = apiPayload.grade_updated;
    this.system_size_kw = apiPayload.system_size_kw;
    this.annual_output_kwh = apiPayload.annual_output_kwh;
    this.address = apiPayload.address;
    this.street_address = apiPayload.address;
    this.is_shown = apiPayload.is_shown;
    this.is_starred = apiPayload.is_starred;

    // All-Cash
    this.system_cost_usd = apiPayload.all_cash_upfront_cost_usd;
    this.ghg_savings_tons = apiPayload.all_cash_ghg_savings_tons;
    this.all_cash_payback_period_years =
      apiPayload.all_cash_payback_period_years;
    this.all_cash_annual_savings_usd =
      apiPayload.all_cash_avg_annual_savings_usd;
    this.all_cash_annual_savings_percentage =
      apiPayload.all_cash_avg_annual_savings_percentage;
    this.all_cash_lifetime_savings_usd =
      apiPayload.all_cash_lifetime_savings_usd;
    this.all_cash_irr = apiPayload.all_cash_irr;
    this.all_cash_comment = apiPayload.all_cash_comment;

    // PPA
    this.ppa_rate_usd = apiPayload.solar_ppa_rate_usd_per_kwh;
    this.ppa_annual_savings_usd = apiPayload.ppa_avg_annual_savings_usd;
    this.ppa_annual_savings_percentage =
      apiPayload.ppa_avg_annual_savings_percentage;
    this.ppa_lifetime_savings_usd = apiPayload.ppa_lifetime_savings_usd;
    this.ppa_comment = apiPayload.ppa_comment;

    // Community Solar
    this.cs_new_bill_usd = apiPayload.cs_recurring_bill_usd;
    this.cs_annual_savings_usd = apiPayload.cs_avg_annual_savings_usd;
    this.cs_annual_savings_percentage =
      apiPayload.cs_avg_annual_savings_percentage;
    this.cs_lifetime_savings_usd = apiPayload.cs_lifetime_savings_usd;
    this.cs_comment = apiPayload.cs_comment;

    // EV Charging
    this.ev_num_ac_stations = apiPayload.ev_charging_ac_stalls_count;
    this.ev_num_dc_stations = apiPayload.ev_charging_dc_stalls_count;
    this.ev_annual_savings_usd = apiPayload.ev_charging_avg_annual_savings_usd;
    this.ev_annual_savings_percentage =
      apiPayload.ev_charging_avg_annual_savings_percentage;
    this.ev_lifetime_savings_usd = apiPayload.ev_charging_lifetime_savings_usd;
    this.ev_comment = apiPayload.ev_charging_comment;

    // Physical location information - often the inputs to our metrics calcs
    this.building_type = apiPayload.building_type; // not yet used but will be useful
    this.roof_area_sqft = apiPayload.roof_area_sqft;
    this.floor_area_sqft = apiPayload.floor_area_sqft;
    this.num_stories = apiPayload.stories;
    this.annual_peak_kw = apiPayload.annual_peak_kw;
    this.annual_usage_kwh = apiPayload.annual_usage_kwh;
    this.annual_utility_bill_usd = apiPayload.annual_utility_bill_usd;
    this.cost_of_electricity_total_usd = apiPayload.coe_total;

    // ITC Adders (Incentives)
    this.census_tract_id = apiPayload.census_tract_id;
    this.itc_total = apiPayload.itc_total;
    this.itc_adder_low_income_or_tribal_community =
      apiPayload.itc_adder_low_income_or_tribal_community;
    this.itc_adder_energy_community = apiPayload.itc_adder_energy_community;
    this.is_low_income_community = apiPayload.is_low_income_community;
    this.is_tribal_community = apiPayload.is_tribal_community;
    this.is_coal_community = apiPayload.is_coal_community;
    this.is_fossil_fuel_employment_community =
      apiPayload.is_fossil_fuel_employment_community;
  }
  id: string;
  status: "succeeded" | "todo" | "doing" | "failed";
  grade: string | null;
  grade_created: string | null;
  grade_updated: string | null;
  system_size_kw: number | null;
  annual_output_kwh: number | null;
  system_cost_usd: number | null;
  ghg_savings_tons: number | null;
  address: string | null;
  street_address: string | null;
  is_shown: boolean | null;
  is_starred: boolean | null;

  // All-Cash
  all_cash_payback_period_years: number | null;
  all_cash_annual_savings_usd: number | null;
  all_cash_annual_savings_percentage: number | null;
  all_cash_lifetime_savings_usd: number | null;
  all_cash_irr: number | null;
  all_cash_comment: string | null;

  // PPA
  ppa_rate_usd: number | null;
  ppa_annual_savings_usd: number | null;
  ppa_annual_savings_percentage: number | null;
  ppa_lifetime_savings_usd: number | null;
  ppa_comment: string | null;

  // Community Solar
  cs_new_bill_usd: number | null;
  cs_annual_savings_usd: number | null;
  cs_annual_savings_percentage: number | null;
  cs_lifetime_savings_usd: number | null;
  cs_comment: string | null;

  // EV Charging
  ev_num_dc_stations: number | null;
  ev_num_ac_stations: number | null;
  ev_annual_savings_usd: number | null;
  ev_annual_savings_percentage: number | null;
  ev_lifetime_savings_usd: number | null;
  ev_comment: string | null;

  // Physical location information
  building_type: string | null;
  roof_area_sqft: number | null;
  floor_area_sqft: number | null;
  num_stories: number | null;
  annual_peak_kw: number | null;
  annual_usage_kwh: number | null;
  annual_utility_bill_usd: number | null;
  cost_of_electricity_total_usd: number | null;

  // ITC Adders (Incentives)
  itc_adder_energy_community: number | null;
  itc_adder_low_income_or_tribal_community: number | null;
  census_tract_id: string | null;
  itc_total: number | null;
  is_low_income_community: boolean | null;
  is_tribal_community: boolean | null;
  is_coal_community: boolean | null;
  is_fossil_fuel_employment_community: boolean | null;
}

export class PortfolioLocationOverview {
  constructor(apiPayload: { [key: string]: any }) {
    this.id = apiPayload.properties.location_id;
    this.type = apiPayload.properties.asset_type;
    this.roof_area_sqft = apiPayload.properties.roof_area_sqft;
    this.floor_area_sqft = apiPayload.properties.floor_area_sqft;
    this.num_stories = apiPayload.properties.stories;
    this.annual_peak_kw = apiPayload.properties.annual_peak_kw;
    this.annual_usage_kwh = apiPayload.properties.annual_usage_kwh;
    this.annual_utility_bill_usd =
      apiPayload.properties.annual_utility_bill_usd;
    this.cost_of_electricity_total_usd = apiPayload.properties.coe_total;
    this.geometry = apiPayload.geometry;
  }
  id: string;
  type: string;
  roof_area_sqft: number;
  floor_area_sqft: number;
  num_stories: number;
  annual_peak_kw: number;
  annual_usage_kwh: number;
  annual_utility_bill_usd: number;
  cost_of_electricity_total_usd: number;
  geometry: { [key: string]: any };
}

export interface Note {
  note: string;
  account_id: string;
}

export type LocationCardField = {
  field: string;
  name: string;
  description: string;
  type: string;
  unit: string;
  sortable: boolean;
  icon: string;
  [key: string]: any;
};

export type LocationCardFieldMap = {
  [key: string]: LocationCardField;
};

export type LocationCardSectionList = {
  [key: string]: LocationCardField[];
};

export type LocationCardSchemaKeyMap = {
  [key: string]: string[];
};

export type LocationEverything = PortfolioLocationOffers &
  PortfolioLocationOverview;
