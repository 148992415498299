<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  readOnly?: boolean;
  modelValue?: boolean | null;
}>();
const emit = defineEmits<{
  // TODO: refactor to update:modelValue when updated to use v-model upstream
  (e: "change", value: boolean): void;
  (e: "update:modelValue", value: boolean): void;
}>();

const dynamicClasses = computed(() => {
  const classes = [
    "tw-checkbox",
    "tw-sta-input",
    "focus:!tw-border-success-400",
    "dark:focus:!tw-border-success-600",
    "!tw-w-min",
  ];
  return classes.join(" ");
});
</script>

<template>
  <input
    type="checkbox"
    :disabled="props.readOnly"
    :checked="!!modelValue"
    @change="emit('change', !modelValue)"
    @input="emit('update:modelValue', !modelValue)"
    :class="dynamicClasses"
  />
</template>
