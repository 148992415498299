<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { onKeyStroke } from "@vueuse/core";

const emit = defineEmits<{
  (e: "open"): void;
  (e: "close"): void;
  (e: "update:modelValue", open: boolean): void;
}>();

// Defines whether this component starts out as being open
// Can be left undefined (which is falsey) or be set by the parent.
const props = defineProps<{
  modelValue: boolean;
  persistent?: boolean;
}>();

const background = ref<HTMLElement | null>(null);
const reactiveValue = computed(() => props.modelValue);

function open() {
  emit("update:modelValue", true);
  emit("open");
}

function close() {
  emit("update:modelValue", false);
  emit("close");
}

onKeyStroke("Escape", () => {
  if (!props.persistent) {
    close();
  }
});

const handleClickOutside = (event: Event) => {
  const clickIsOnBackground =
    background.value && background.value === (event.target as Node);

  if (reactiveValue.value && clickIsOnBackground && !props.persistent) {
    close();
  }
};
onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside);
});
</script>

<template>
  <!-- Unfortunately need this wrapper <span> because we're still using Vue 2 -->
  <span>
    <!-- Default slot is for binding to a button or other element that invokes the dialog -->
    <div>
      <slot name="activator" v-bind="{ open }" />
    </div>
    <!-- dialog background, full size of screen -->
    <div
      ref="background"
      class="tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full md:tw-p-10 tw-bg-black/75 dark:!tw-bg-black/80 tw-grid tw-items-center tw-z-[1000]"
      v-if="reactiveValue"
    >
      <slot name="content" v-bind="{ close }" />
    </div>
  </span>
</template>
