<template>
  <TDialog :model-value="true" persistent>
    <template #content>
      <loading-state-card
        v-if="loading"
        icon="sync"
        card-title="Loading"
        dialog
      />
      <t-card dialog v-else-if="!isAuthed">
        <t-card-title>
          <t-icon left>login</t-icon>
          <h2 class="tw-text-xl">Sign in to join "{{ organizationName }}"</h2>
        </t-card-title>
        <t-card-text class="tw-subheading">
          <p>
            Please sign in to join "{{ organizationName }}". Joining this
            organization will allow you to access its shared materials and to
            collaborate with your teammates.
          </p>
        </t-card-text>
        <t-card-actions class="tw-justify-end">
          <TBtn @click="$beacon.redirect(accountsAppUrl)">
            Sign in / Sign up
          </TBtn>
        </t-card-actions>
      </t-card>
      <t-card dialog v-else-if="tokenInvalid || joinOrgError">
        <t-card-title>
          <t-icon left>person_add_disabled</t-icon>
          <h2 class="tw-text-xl">Invalid invite link</h2>
        </t-card-title>
        <t-card-text class="tw-subheading">
          <p>
            The provided invite link to join your organization is improperly
            formatted. Please request a new invite link at
            <a href="mailto:support@stationa.com" target="_blank">
              support@stationa.com</a
            >.
          </p>
        </t-card-text>
        <t-card-actions class="tw-justify-end">
          <TBtn @click="$emit('close')">Close</TBtn>
        </t-card-actions>
      </t-card>
      <t-card dialog v-else-if="tokenExpired">
        <t-card-title>
          <t-icon left>person_add_disabled</t-icon>
          <h2 class="tw-text-xl">
            Invite link to join "{{ organizationName }}" has expired
          </h2>
        </t-card-title>
        <t-card-text class="tw-subheading">
          <p>
            The invite link to join this organization has expired. Please
            request a new invite link at
            <a href="mailto:support@stationa.com" target="_blank">
              support@stationa.com</a
            >.
          </p>
        </t-card-text>
        <t-card-actions class="tw-justify-end">
          <TBtn @click="$emit('close')">Close</TBtn>
        </t-card-actions>
      </t-card>
      <t-card dialog v-else-if="sameOrganization">
        <t-card-title>
          <t-icon left>how_to_reg</t-icon>
          <h2 class="tw-text-xl">
            You have already joined "{{ organizationName }}"
          </h2>
        </t-card-title>
        <t-card-text class="tw-subheading">
          <p>
            You are already able to access its shared materials and to
            collaborate with your teammates at "{{ organizationName }}".
          </p>
        </t-card-text>
        <t-card-actions class="tw-justify-end">
          <TBtn @click="$emit('close')">Close</TBtn>
        </t-card-actions>
      </t-card>
      <t-card dialog v-else-if="currentOrganizationName">
        <t-card-title>
          <t-icon left>group_remove</t-icon>
          <h2 class="tw-text-xl">
            Leave "{{ currentOrganizationName }}" to join "{{
              organizationName
            }}"?
          </h2>
        </t-card-title>
        <t-card-text class="tw-subheading">
          Leaving your current organization "{{ currentOrganizationName }}" will
          cause you to lose access to the materials linked with this
          organization. Joining the organization "{{ organizationName }}" will
          allow you to access its shared materials and to collaborate with your
          teammates.
        </t-card-text>
        <t-card-actions class="tw-justify-between">
          <TBtn @click="$emit('close')">Cancel</TBtn>
          <TBtn color="green" @click="confirmJoinOrg">Confirm</TBtn>
        </t-card-actions>
      </t-card>
      <t-card dialog v-else>
        <t-card-title>
          <t-icon left>group_add</t-icon>
          <h2 class="tw-text-xl">Join organization "{{ organizationName }}"</h2>
        </t-card-title>
        <t-card-text class="tw-subheading">
          Joining this organization will allow you to access its shared
          materials and to collaborate with your teammates.
        </t-card-text>
        <t-card-actions class="tw-justify-between">
          <TBtn @click="$emit('close')">Cancel</TBtn>
          <TBtn color="green" @click="confirmJoinOrg"> Confirm </TBtn>
        </t-card-actions>
      </t-card>
    </template>
  </TDialog>
</template>

<script>
import { getAccountsAppUrl } from "../lib/useAuth";
import { computed } from "vue";
import { useJwt } from "@vueuse/integrations/useJwt";
import { useTokenRefresher } from "../lib/useTokenRefresher";
import LoadingStateCard from "@/components/LoadingStateCard.vue";

export default {
  name: "org-invite-confirmation-dialog",
  props: {
    inviteToken: {
      type: String,
      required: true,
    },
    currentOrganizationId: {
      type: String,
    },
    currentOrganizationName: {
      type: String,
    },
    // We are using an isAuthed prop instead of using the useAuth composable
    // so it jives well with the cypress component testing
    isAuthed: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const { refreshToken } = useTokenRefresher();
    const { payload: inviteObject } = useJwt(props.inviteToken);

    const tokenInvalid = computed(() => {
      return (
        !inviteObject.value ||
        !inviteObject.value.organization_id ||
        !inviteObject.value.organization_name ||
        !inviteObject.value.exp
      );
    });

    const tokenValid = computed(() => !tokenInvalid.value);

    const sameOrganization = computed(() => {
      return (
        tokenValid.value &&
        !!props.currentOrganizationId &&
        props.currentOrganizationId === inviteObject.value.organization_id
      );
    });

    const tokenExpired = computed(() => {
      return tokenValid.value && inviteObject.value.exp < Date.now() / 1000;
    });

    const organizationName = computed(() => {
      if (tokenValid.value) {
        return inviteObject.value.organization_name;
      } else {
        return null;
      }
    });

    return {
      organizationName,
      refreshToken,
      sameOrganization,
      tokenExpired,
      tokenInvalid,
      tokenValid,
    };
  },
  components: {
    LoadingStateCard,
  },
  data() {
    return {
      loading: false,
      joinOrgError: false,
    };
  },
  computed: {
    accountsAppUrl() {
      return getAccountsAppUrl();
    },
  },
  methods: {
    async confirmJoinOrg() {
      this.loading = true;
      try {
        await this.$api.Organization.joinOrg(this.inviteToken);
        await this.refreshToken();
        this.$emit("close");
      } catch (e) {
        this.joinOrgError = true;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
