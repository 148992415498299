<script setup lang="ts">
import { ref } from "vue";

import { useAccessToken } from "../lib/useAccessToken";
import { useTerms } from "../lib/useTerms";
import { AccountClient } from "../lib/API";

const emit = defineEmits<{
  (event: "accept"): void;
  (event: "reject"): void;
}>();

const { clearAccessToken } = useAccessToken();
const { acceptTerms, termsAreUpToDate, termsLoading } = useTerms();

const termsAgreed = ref(false);

const accountClient = new AccountClient();

async function onAcceptTerms() {
  await acceptTerms();
  emit("accept");
}
async function onRejectTerms() {
  await accountClient.logout();
  clearAccessToken();
  emit("reject");
}
</script>

<template>
  <TDialog persistent :model-value="!termsLoading && !termsAreUpToDate">
    <template #content>
      <t-card dialog>
        <t-card-title>
          <t-icon left>description</t-icon>
          <h2 class="tw-heading">Our terms have changed</h2>
        </t-card-title>
        <t-card-text class="tw-text-base tw-pt-0">
          <p>
            We have updated our Terms of Service, which will be effective on
            October 7, 2021. By checking the box and clicking "I Agree" below,
            you acknowledge that you have read, understood, and agree to the
            updated
            <a href="https://stationa.com/terms" target="_blank"
              >Station A Terms of Service</a
            >.
          </p>
          <div class="tw-flex tw-items-center tw-gap-4 tw-mt-8">
            <TCheckbox v-model="termsAgreed"></TCheckbox>
            <span class="tw-text-zinc-600 dark:tw-text-zinc-300">
              I have read, understood, and agree to the updated terms
            </span>
          </div>
        </t-card-text>
        <t-card-actions class="tw-justify-between">
          <TBtn @click="onRejectTerms">I do not agree</TBtn>
          <TBtn @click="onAcceptTerms" :disabled="!termsAgreed" color="green">
            I agree
          </TBtn>
        </t-card-actions>
      </t-card>
    </template>
  </TDialog>
</template>
