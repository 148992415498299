import { formatDistance, formatDistanceToNow } from "date-fns";
import { formatInTimeZone, format as formatDate } from "date-fns-tz/esm";
// TODO: Update usage per numbro's newer API vs. the old numeral.js API
// https://numbrojs.com/format.html
import numeral from "numbro";
// Trying to keep the default formatting ("0,0") the same as it used to be in numeral.js
numeral.setDefaults({
  thousandSeparated: true,
  mantissa: 0,
});

export function aggValue(n, unit) {
  if (unit === "kW" || unit === "kWh") {
    const baseUnit = unit.substring(1);
    if (n < 10) {
      return `${numeral(n).format("0,0.0")} k${baseUnit}`;
    } else if (n < 1000) {
      return `${numeral(n).format("0,0")} k${baseUnit}`;
    } else if (n < 10000) {
      return `${numeral(n / 1000).format("0,0.0")} M${baseUnit}`;
    } else if (n < 1000000) {
      return `${numeral(n / 1000).format("0,0")} M${baseUnit}`;
    } else if (n < 10000000) {
      return `${numeral(n / 1000000).format("0,0.0")} G${baseUnit}`;
    } else if (n < 1000000000) {
      return `${numeral(n / 1000000).format("0,0")} G${baseUnit}`;
    }
    return `${numeral(n / 1000000000).format("0,0.0")} T${baseUnit}`;
  }
  if (unit === "%") {
    return `${numeral(n).format("0,0.0%")}`;
  }
  if (unit === "sqft") {
    if (n < 1000000) {
      return `${numeral(n).format("0,0")} ft&sup2;`;
    } else if (n < 1000000000) {
      return `${numeral(n / 1000000).format("0,0.0")} MM ft&sup2;`;
    } else if (n < 1000000000000) {
      return `${numeral(n / 1000000000).format("0,0.0")} B ft&sup2;`;
    }
    return `${numeral(n / 1000000000000).format("0,0.0")} T ft&sup2;`;
  }
  if (unit === "ft") {
    return `${numeral(n).format("0,0")} ft`;
  }
  if (unit === "USD") {
    return `$${numeral(n).format("0,0.000")}`;
  }
  if (unit === "sumUSD") {
    if (n < 1000000) {
      return `$${numeral(n).format("0,0")}`;
    } else if (n < 1000000000) {
      return `$${numeral(n / 1000000).format("0,0.0")}M`;
    } else if (n < 1000000000000) {
      return `$${numeral(n / 1000000000).format("0,0.0")}B`;
    }
    return `${numeral(n / 1000000000000).format("0,0.0")}T`;
  }
  if (unit === "story") {
    n = Math.round(n);
    if (n === 1) {
      return `${numeral(n).format("0")} story`;
    } else {
      return `${numeral(n).format("0,0")} stories`;
    }
  }
  if (unit === "location") {
    n = Math.round(n);
    if (n === 0) {
      return "No locations";
    } else if (n === 1) {
      return `${numeral(n).format("0")} location`;
    } else {
      return `${numeral(n).format("0,0")} locations`;
    }
  }
  if (unit === "project") {
    n = Math.round(n);
    if (n === 0) {
      return "No projects";
    } else if (n === 1) {
      return `${numeral(n).format("0")} project`;
    } else {
      return `${numeral(n).format("0,0")} projects`;
    }
  }
  const maybeUnit = unit ? ` ${unit}` : "";
  if (n < 1000) {
    return `${n} ${maybeUnit}`;
  }
  return `${numeral(n).format("0,0")} ${maybeUnit}`;
}

export function tableValue(n, unit) {
  if (typeof n === "boolean") {
    return unit;
  }
  if (unit === "kW" || unit === "kWh") {
    const baseUnit = unit.substring(1);
    return `${numeral(n).format("0,0")} k${baseUnit}`;
  }
  if (unit === "%") {
    if (n <= 0) {
      return "0.0%";
    } else if (n >= 1) {
      return "100.0%";
    } else {
      return `${numeral(n * 100).format("0,0.0")}%`;
    }
  }
  if (unit === "sqft") {
    return `${numeral(n).format("0,0")} ft²`;
  }
  if (unit === "USD") {
    return `$${numeral(n).format("0,0.000")}`;
  }
  if (unit === "USD/kWh") {
    if (!n) return;
    return `${numeral(n).format("0,0.0000")} USD/kWh`;
  }
  if (unit === "sumUSD") {
    if (n <= 0) {
      return "$0";
    } else {
      return `$${numeral(n).format("0,0")}`;
    }
  }
  if (unit === "station") {
    n = Math.round(n);
    if (n === 1) {
      return `${numeral(n).format("0")} station`;
    } else {
      return `${numeral(n).format("0")} stations`;
    }
  }
  if (unit === "story") {
    n = Math.round(n);
    if (n === 1) {
      return `${numeral(n).format("0")} story`;
    } else {
      return `${numeral(n).format("0")} stories`;
    }
  }
  if (unit === "year") {
    n = Math.round(n);
    if (n < 1) {
      return "<1 year";
    } else if (n === 1) {
      return `${numeral(n).format("0")} year`;
    } else {
      return `${numeral(n).format("0")} years`;
    }
  }
  if (unit === "ton") {
    return `${numeral(n).format("0,0")} tn`;
  }
  if (unit === "timeago") {
    if (n) {
      return formatDistanceToNow(new Date(n), { addSuffix: true });
    }
    return "-";
  }
}

export function date(date) {
  return formatInTimeZone(date, "America/Los_Angeles", "MMMM d, yyyy");
}

export function currency(n, precision = 2) {
  let decimalPoints = "";
  for (let i = 0; i < precision; i++) {
    decimalPoints = `${decimalPoints}0`;
  }
  return `$${numeral(n / 100).format("0,0." + decimalPoints)}`;
}

export function oneLineAddress(addr) {
  const firstLine = addr.split(", ")[0];
  const secondLine = addr.split(", ").slice(1).join(", ");
  return {
    firstLine,
    secondLine,
  };
}

export function datetime(date) {
  return formatInTimeZone(
    date,
    "America/Los_Angeles",
    "MMMM d, yyyy @ h:mma z"
  );
}

export function datetimeWithDayOfWeek(date) {
  return formatInTimeZone(
    date,
    "America/Los_Angeles",
    "EE MMMM d, yyyy @ h:mma z"
  );
}

export default {
  install: (app) => {
    // TODO: Move this out into a separate library module so that we can more easily write unit
    // tests for expected behavior!
    // aggValue is already on the way, as an example for this
    app.config.globalProperties.$format = {
      metricValue(n) {
        if (n < 1000) {
          return [`${numeral(n).format("0,0")}`, null];
        } else if (n < 1000000) {
          return [`${numeral(n / 1000).format("0,0")}`, "K"];
        } else if (n < 1000000000) {
          return [`${numeral(n / 1000000).format("0,0")}`, "M"];
        }
        return [`${numeral(n / 1000000000).format("0,0.0")}`, "G"];
      },
      aggValue: aggValue,
      tableValue: tableValue,
      currency: currency,
      address(addr) {
        const firstLineComponents = [];
        const secondLineComponents = [];
        if (addr.streetAddress) {
          firstLineComponents.push(addr.streetAddress);
        } else if (addr.route) {
          if (addr.streetNumber) {
            firstLineComponents.push(addr.streetNumber + " " + addr.route);
          } else {
            firstLineComponents.push(addr.route);
          }
        }
        if (addr.region) {
          if (addr.locality) {
            secondLineComponents.push(addr.locality + ", " + addr.region);
          } else {
            secondLineComponents.push(addr.region);
          }
        }
        if (addr.postalCode) {
          if (addr.postalCodeSuffix) {
            const full = addr.postalCode + "-" + addr.postalCodeSuffix;
            secondLineComponents.push(full);
          } else {
            secondLineComponents.push(addr.postalCode);
          }
        }
        const firstLine = firstLineComponents.join(" ");
        const secondLine = secondLineComponents.join(" ");
        const oneLine = [firstLine, secondLine]
          .filter((l) => l !== "")
          .join(", ");
        return {
          firstLine,
          secondLine,
          oneLine,
        };
      },
      capitalize(n) {
        return n.charAt(0).toUpperCase() + n.slice(1);
      },
      costOfElectricity(n, currency = "USD") {
        let displayCurrency = null;
        if (currency === "USD") {
          displayCurrency = "$";
        } else if (currency === "CAD") {
          displayCurrency = "C$";
        }
        return `${displayCurrency}${numeral(n).format("0,0.000")}/kWh`;
      },
      decimal(n, precision = 2) {
        let formatStr = "0,0.";
        for (let i = 0; i < precision; i++) {
          formatStr += "0";
        }
        return numeral(n).format(formatStr);
      },
      integer(n) {
        return numeral(n).format("0,0");
      },
      date: date,
      datetime: datetime,
      localizedDate(date) {
        return formatDate(date, "PPP");
      },
      localizedDatetime(date) {
        return formatDate(date, "PPP @ pp");
      },
      timeDiff(date, comparison) {
        return formatDistance(date, comparison, { addSuffix: true });
      },
      percentage(n, precision = 0) {
        let fmt = "0,0";
        for (let i = 0; i < precision; i++) {
          if (i === 0) {
            fmt += ".";
          }
          fmt += "0";
        }
        return `${numeral(n * 100).format(fmt)}%`;
      },
      roundedValue(n, nonZeroDigits = 2) {
        const digitBasis = Math.max(n, 1);
        const numDigits = Math.floor(Math.log10(digitBasis)) + 1;
        const digitReductionNeeded = numDigits - nonZeroDigits;
        const multiplier = Math.pow(10, digitReductionNeeded);
        return multiplier * Math.round(n / multiplier);
      },

      timeago(date) {
        return formatDistanceToNow(date, { addSuffix: true });
      },
    };
  },
};
