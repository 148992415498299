import * as Sentry from "@sentry/vue";
import type { BrowserOptions } from "@sentry/vue";
import type { VueOptions } from "@sentry/vue/build/types/types";
import type { Router } from "vue-router";

interface ExtraTags {
  [key: string]: any;
}

interface User {
  accountId: string | null;
  groups: string[];
  userType: string | null;
}

/**
 * Updates the current Sentry scope to include user context
 */
export function updateSentryContext(ctx: User & ExtraTags) {
  const { accountId, groups, userType, ...extraTags } = ctx;
  if (accountId) {
    Sentry.setUser({
      id: accountId || undefined,
      groups,
      user_type: userType,
    });
  } else {
    Sentry.setUser(null);
  }
  Sentry.setTag("user_type", userType);
  Sentry.setTags(extraTags);
}

/**
 * Installs Sentry along with default uncaught exception handling
 */
export function useSentry(
  router: Router,
  options: Partial<BrowserOptions> & Partial<VueOptions>
) {
  Sentry.init({
    ...options,
    integrations: [
      Sentry.replayIntegration(),
      Sentry.browserTracingIntegration({
        router,
      }),
    ],
  });
}
